import React from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { CustomQuestionsData } from '../Interface';
import { FormDataType } from '../../applyPopup';

const CheckboxQuestion = ({
  id,
  questionString,
  answerOptions,
  sno,
  formData,
  updateCheckboxFormData,
}: CustomQuestionsData & {
  sno: number;
  formData: FormDataType;
  updateCheckboxFormData: (id: string, checked: boolean, value: string) => void;
}) => {
  return (
    <div className='custom-question'>
      <p>{sno + '. ' + questionString}</p>
      <div className='checkbox-field'>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {answerOptions.map((option, idx) => (
            <FormControlLabel
              key={idx}
              value={option}
              control={
                <Checkbox
                  checked={formData[id].includes(option)}
                  onChange={(e) => {
                    updateCheckboxFormData(id, e.target.checked, option);
                  }}
                />
              }
              label={option}
            />
          ))}
        </Box>
      </div>
    </div>
  );
};

export default CheckboxQuestion;
