import React, {
  FormEvent,
  useState,
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useContext,
} from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { GrClose } from 'react-icons/gr';
import CheckboxQuestion from './components/questionTypes/CheckboxQuestion';
import RadioQuestion from './components/questionTypes/RadioQuestion';
import TextQuestion from './components/questionTypes/TextQuestion';
// api
import {
  fetchCompanyFromJobCardIdService,
  fetchCustomQuestionsService,
  postCustomQuestionsService,
} from './service';
import {
  CustomQuestionsData,
  CustomQuestionSubmission,
} from './components/Interface';
import { Context } from '../../Context';
import Loader from '../../components/Loader';

export interface PopupState {
  isOpen: boolean;
  identifier: string;
}

export interface SuccessState {
  status: boolean;
  process: string;
}

export interface CustomQuestion {
  id: string;
  type: string;
  statement: string;
  options: string[];
}

export interface FormDataType {
  [index: string]: string | string[];
}

interface FinalFormDataType {
  jobCardQuestion: string;
  answer: string[];
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return (
    <Slide
      direction='up'
      ref={ref}
      {...props}
    />
  );
});

interface PopupProps {
  applyPopup: PopupState;
  setApplyPopup: (open: PopupState) => void;
  setSuccess: (data: SuccessState) => void;
}

const ApplyPopup = ({ applyPopup, setApplyPopup, setSuccess }: PopupProps) => {
  const { token } = useContext(Context);
  // loading state
  const [loading, setLoading] = useState(false);
  const [customQuestions, setCustomQuestions] = useState(
    [] as CustomQuestionsData[]
  );
  const [formData, setFormData] = useState({} as FormDataType);
  const [companyName, setCompanyName] = useState('');
  const [emptyFieldError, setEmptyFieldError] = useState(false);







  useEffect(() => {
    const fetchCustomQuestions = async () => {
      if (applyPopup.identifier !== '') {
        setLoading(true);
        const companyNameResponse = await fetchCompanyFromJobCardIdService(
          applyPopup.identifier,
          token
        );
        setCompanyName(companyNameResponse);

        const data = await fetchCustomQuestionsService(
          applyPopup.identifier,
          token
        );
        const customQuestions = data.data;
        setFormData((initialFormData) => {
          let newFormData = { ...initialFormData };
          for (let i = 0; i < customQuestions.length; i += 1) {
            if (customQuestions[i].questionType === 'MULTIMCQ')
              newFormData = {
                ...newFormData,
                [customQuestions[i].id]: [] as string[],
              };
            else
              newFormData = {
                ...newFormData,
                [customQuestions[i].id]: '',
              };
          }
          return newFormData;
        });
        setCustomQuestions(data.data);

        setTimeout(() => {
          setLoading(false);
        }, 300);
      }
    };

    fetchCustomQuestions();
  }, [applyPopup]);

  const handleClose = () => {
    setApplyPopup({
      isOpen: false,
      identifier: '',
    });
    setTimeout(() => {
      setFormData({} as FormDataType);
      setCustomQuestions([] as CustomQuestionsData[]);
    }, 300);
  };

  const updateFormData = (id: string, value: string) => {
    setFormData({ ...formData, [id]: value });
  };

  const updateCheckboxFormData = (
    id: string,
    checked: boolean,
    value: string
  ) => {
    setFormData((oldFormData) => {
      const newFormData = { ...oldFormData };
      if (checked) (newFormData[id] as string[]).push(value);
      else
        (newFormData[id] as string[]).splice(newFormData[id].indexOf(value), 1);
      return newFormData;
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const finalFormData: FinalFormDataType[] = [];
    let emptyField = false;

    for (let i = 0; i < Object.entries(formData).length; i += 1) {
      const [key, value] = Object.entries(formData)[i];
      finalFormData.push({
        jobCardQuestion: key,
        answer: typeof value === 'string' ? [value] : value,
      });
      if (finalFormData[finalFormData.length - 1].answer.length === 0) {
        emptyField = true;
        break;
      }
    }

    if (emptyField === true) {
      setEmptyFieldError(true);
      setTimeout(() => {
        setEmptyFieldError(false);
      }, 4500);
      return;
    }

    const submissionData: CustomQuestionSubmission = {
      jobCardId: applyPopup.identifier,
      customQuestionAnswers: finalFormData,
    };
    const postCustomQuestions = await postCustomQuestionsService(
      submissionData,
      token
    );
    console.log(postCustomQuestions);
    if (
      postCustomQuestions.data.message === 'successfully saved complete data'
    ) {
      setSuccess({
        status: true,
        process: 'completed',
      });
    } else {
      setSuccess({
        status: false,
        process: 'completed',
      });
    }
    handleClose();
  };

  return (
    <div className='job-portal-custom-questions-popup-inner'>
      {loading === true ? <Loader /> : null}
      {/* {loading === false && customQuestions.length === 0 && (
        <>
          <div className='popup-header'>
            <div>
              {companyName && <h3>{companyName}</h3>}
              <p>Are you sure you want to apply to this job?</p>
            </div>
            <GrClose
              onClick={() => {
                handleClose();
              }}
            />
          </div>
          <div className='confirmation-buttons'>
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
              className='confirmation-buttons'
            >
              <Button
                type='submit'
                className='apply-now'
              >
                Yes, Apply now!
              </Button>
              <Button
                className='cancel'
                onClick={() => {
                  handleClose();
                }}
              >
                No, Maybe later!
              </Button>
            </form>
          </div>
        </>
      )} */}
      {loading === false && customQuestions.length !== 0 && (
        <>
          <div className='popup-header'>
            <div>
              {companyName && <h3>{companyName}</h3>}
              <p>
                Please answer the following questions as a part of the
                application process:
              </p>
            </div>
          </div>

          <div className='popup-body'>
            {/* Form Begin */}
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              {customQuestions.map((question, idx) => {
                if (question.questionType === 'TEXT')
                  return (
                    <TextQuestion
                      key={idx}
                      sno={idx + 1}
                      formData={formData}
                      updateFormData={updateFormData}
                      {...question}
                    />
                  );
                else if (question.questionType === 'MCQ')
                  return (
                    <RadioQuestion
                      key={idx}
                      sno={idx + 1}
                      formData={formData}
                      updateFormData={updateFormData}
                      {...question}
                    />
                  );
                else
                  return (
                    <CheckboxQuestion
                      key={idx}
                      sno={idx + 1}
                      formData={formData}
                      updateCheckboxFormData={updateCheckboxFormData}
                      {...question}
                    />
                  );
              })}
              <div className='popup-footer'>
                <Button
                  type='submit'
                  className='custom-questions-submit'
                >
                  Apply Now
                </Button>
                {emptyFieldError && (
                  <span>
                    All fields are required, please fill all fields to continue.
                  </span>
                )}
              </div>
            </form>
            {/* Form End */}
          </div>
        </>
      )}
    </div>
  );
};

export default ApplyPopup;
