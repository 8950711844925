import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Jobs from "./pages/JobPortal/jobs";
import RedirectAction from "./pages/RedirectAction/redirect";

const AppRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/jobs' element={<Jobs />} />
      <Route path='/redirect' element={<RedirectAction />} />
    </Routes>
  );
};

export default AppRouter;
