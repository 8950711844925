import React from 'react';
import {  SyncLoader } from 'react-spinners';

const Loader = () => {
  return (
    <div className={'loader'}>
      <SyncLoader color={'#2666BE'} />
    </div>
  );
};

export default Loader;
