import { TextField } from '@mui/material';
import React from 'react';
import { CustomQuestionsData } from '../Interface';
import { FormDataType } from '../../applyPopup';

const TextQuestion = ({
  id,
  questionString,
  sno,
  formData,
  updateFormData,
}: CustomQuestionsData & {
  sno: number;
  formData: FormDataType;
  updateFormData: (id: string, value: string) => void;
}) => {
  return (
    <div className='custom-question'>
      <p>{sno + '. ' + questionString}</p>
      <div className='text-field'>
        <TextField
          placeholder='Enter Answer Here...'
          multiline
          required
          value={formData[id]}
          onChange={(e) => updateFormData(id, e.target.value)}
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
          }}
          size='small'
        />
      </div>
    </div>
  );
};

export default TextQuestion;
