import React, { useContext, useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import "./App.scss";
import "./styles/styles.scss";
import logo from "./assets/acciojob_logo.svg";
import AppRouter from "./AppRouter";
import Portal from "./Portal";
import ValidateEmail from "./components/validateEmail";
import { Context } from "./Context";
import { ValidateEmailService } from "./services/global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const location = useLocation();
  const { setEmail, setToken, token } = useContext(Context);
  const email = queryString.parse(location.search).email;
  const [isValidateModalOpen, setIsValidateModalOpen] = useState(true);

  const closeHandler = () => {
    setIsValidateModalOpen(false);
  };

  const emailValidation = async () => {
    try {
      const body = {
        user: email,
      };
      const { accioJobsToken } = await ValidateEmailService(body);
      console.log("accioJobsToken", accioJobsToken);
      setEmail(email);
      setToken(accioJobsToken);
      closeHandler();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (email !== undefined) {
      setEmail(email);
      emailValidation();
    }
  }, [email]);

  return (
    <div className='App'>
      <nav className='banner'>
        <img src={logo} alt='acciojob-logo' />
        <h2>Acciojob</h2>
      </nav>
      {location.pathname != "/redirect" ? (
        isValidateModalOpen ? (
          <Portal>
            <ValidateEmail closeHandler={closeHandler} />
          </Portal>
        ) : token === null ? (
          <div>
            Validate Email
            <button
              onClick={() => {
                setIsValidateModalOpen(true);
              }}
            >
              Validate
            </button>
          </div>
        ) : null
      ) : null}

      <AppRouter />
      <ToastContainer />
      {/* <footer>
        <p>
          Copyright {new Date().getFullYear()} Acciojob Private Ltd. All Right
          Reserved
        </p>
      </footer> */}
    </div>
  );
}

export default App;
