import React, { useState } from 'react';
import Portal from '../Portal';

const Home = () => {
  const [openPortal, setOpenPortal] = useState(false);
  return (
    <div>
      inside react
      {openPortal ? (
        <Portal>
          <h1>Portal</h1>
        </Portal>
      ) : null}
      <button onClick={() => setOpenPortal(!openPortal)}>Portal Toggle</button>
    </div>
  );
};

export default Home;
