import axios from "axios";
import { backendUrl } from "../global";

export const GetTokenPhone = async (phone: string) => {
  try {
    const config = {
      headers: {
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      },
    };
    const url = `${backendUrl}/users/token-by-phone`;
    const { data } = await axios.post(url, {
      phoneNumber: phone
    } ,config);
    return data;
  } catch (error) {
    console.log(error);
  }
};
