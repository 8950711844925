import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../Context';
import { ValidateEmailService } from '../services/global';

const ValidateEmail = ({ closeHandler }: { closeHandler: any }) => {
  const { email, setEmail, setToken } = useContext(Context);
  const [value, setValue] = useState('');

  const emailValidation = async () => {
    try {
      const body = {
        phoneNumberOrEmail: value,
      };
      const { accioJobsToken } = await ValidateEmailService(body);
      console.log('accioJobsToken', accioJobsToken);
      setEmail(value);
      setToken(accioJobsToken);
      closeHandler();
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    setValue(email);
  }, []);

  return (
    <div className='validate-email'>
      <h1>Enter Email Or Phone registered with Acciojob</h1>

      <input
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />
      <div>
        <button
          className='validate-btn'
          onClick={emailValidation}
        >
          Validate
        </button>
        <button
          className='close-btn'
          onClick={closeHandler}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ValidateEmail;
