import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { ValidateEmailService } from "../../services/global";
import { GetTokenPhone } from "../../services/token";
import { toast } from "react-toastify";
import queryString from 'query-string';


function RedirectAction() {
  const location = useLocation();
  const useQueryParams = () => {
    return new URLSearchParams(location.search);
  };

  const parsed = queryString.parse(location.search);

  function decodeToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  }

  const [decodedToken, setDecodedToken] = useState<{
    iat: number;
    id: string;
    phoneNumber: string;
    roles: Array<string>;
  } | null>(null);

  useEffect(() => {
    if (decodedToken) getData();
  }, [decodedToken]);

  useEffect(() => {
    const token = Cookies.get("acciojobs-token");
    console.log("tokenn", token);
    if (token) {
      const decoded = decodeToken(token);
      setDecodedToken(decoded);
      console.log("token", decoded);
    } else getData();
  }, []);

  const getTokenByPhone = async (phone: string) => {
    try {
      const accioJobsToken = await GetTokenPhone(phone);
      console.log("accioJobsToken>>>", accioJobsToken);
      return accioJobsToken;
    } catch (err) {
      console.log(err);
    }
  };

  const query = useQueryParams();
  const hash = location.hash;
  console.log({hash})
  const phoneNumber = query.get("phoneNumber");
  const expiryTime = query.get("expiryTime");
  const action = query.get("action");
  const bootcampId = query.get("bootcampId")
  const redirectURI =
    query.get("redirectURI") + "?source=open-ref&action=" + action + `&bootcampId=${bootcampId}` + `${hash?.length ? hash: ''}`;

  async function getData() {
    if (phoneNumber && redirectURI) {
      console.log("Recieved Phone Number", redirectURI);
      if (expiryTime && new Date() <= new Date(expiryTime)) {
        console.log("Link has not expired");
        if (decodedToken) {
          console.log("Decoded Token", decodedToken);
          if (decodedToken.roles.includes("precourse-user")) {
            console.log("Precourse user! Need to do action");
            window.open(redirectURI, "_self");
          } else {
            window.open("https://authentication.acciojob.com", "_self");
          }
        } else {
          console.log("User Token Not detected!");
          if (phoneNumber) {
            const token = await getTokenByPhone(phoneNumber);
            const decoded = decodeToken(token);
            if (decoded.roles.includes("precourse-user")) {
              Cookies.set("acciojobs-token", token, {
                expires: 1,
                domain: ".acciojob.com",
              });
              window.open(redirectURI, "_self");
            } else {
              window.open("https://authentication.acciojob.com", "_self");
            }
          }
        }
      } else {
        console.log("Link has expired");
        toast.error("Link has expired", { position: "top-center" });
        window.open("https://authentication.acciojob.com", "_self");
      }
    } else {
      console.log("Recieved No Phone or No Action or No Redirect URI");
      if (!phoneNumber) {
        toast.error("No phoneNumber found!", { position: "top-center" });
      } else if (!action) {
        toast.error("No action found!", { position: "top-center" });
      } else {
        toast.error("No RedirectURI found!", { position: "top-center" });
      }
      window.open("https://authentication.acciojob.com", "_self");
    }
  }

  return <div></div>;
}

export default RedirectAction;
