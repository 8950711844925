import { ReactElement, useEffect } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children }: { children: ReactElement }) => {
  const mount = document.getElementById('portal-root');
  const el = document.createElement('div');
  el.classList.add('portal-overlay');

  useEffect((): any => {
    if (mount !== null) {
      mount.appendChild(el);
      return () => mount.removeChild(el);
    }
  }, [el, mount]);

  return createPortal(children, el);
};

export default Portal;
