import axios from 'axios';
import { backendUrl } from '../global';

export const ValidateEmailService = async (body: any) => {
  try {
    const config = {
      headers: {
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      },
    };
    const url = `${backendUrl}/users/admin/login/only`;
    const { data } = await axios.post(url, { ...body }, config);

    return data;
  } catch (error) {
    console.log(error);
  }
};
