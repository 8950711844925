import React, { createContext, useState } from 'react';

export const Context = createContext<any>(null);

interface ContextProps {
  children: React.ReactNode;
}

const ContextProvider = ({ children }: ContextProps) => {
  const [email, setEmail] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);

  return (
    <Context.Provider value={{ email, token, setEmail, setToken }}>
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
