import React, { useContext, useEffect, useState } from 'react';
import queryString from 'query-string';
import Lottie from 'react-lottie-player';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import Portal from '../../Portal';
import ApplyPopup from './applyPopup';
import successLottie from './../../assets/lottie/success.json';
import failed from './../../assets/lottie/failed.json';
import { getJobCardApplicationStatus } from './service';
import { toast } from 'react-toastify';

export interface PopupState {
  isOpen: boolean;
  identifier: any;
}

const Jobs = () => {
  const location = useLocation();
  const jobId = queryString.parse(location.search).job;
  const [openPortal, setOpenPortal] = useState(false);
  const { email, token } = useContext(Context);
  const [applyPopup, setApplyPopup] = useState<PopupState>({
    isOpen: false,
    identifier: '',
  });

  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (token !== undefined || token !== null) {

      if (isAllowed) {
        setOpenPortal(true);
        setApplyPopup({
          isOpen: true,
          identifier: jobId,
        });
      }
    }
  }, [token, isAllowed]);

  useEffect(() => {
    if (token !== undefined || token !== null) {
      getJobApplicationStatus();
    }
  }, [token])

  const [success, setSuccess] = useState({
    status: false,
    process: 'uncompleted',
  });


  const getJobApplicationStatus = async () => {
    if (token && jobId && typeof jobId === 'string') {
      const data = await getJobCardApplicationStatus(jobId, token);
      console.log("getJobCardApplicationStatus", data);
      if (data.data === 'eligible') {
        toast.success('You are eligible for this job')
        setIsAllowed(true)
      }
      else if (data.data === 'applied') {
        toast.success('You have already applied for this job')
        setIsAllowed(false)
      }
      else if (data.data === 'not eligible') {
        toast.error('You have already applied for this job')
        setIsAllowed(false)
      }
    }
  }


  return (
    <div className='jobs'>
      <h3 className='head'>Job Application</h3>
      {openPortal ? (
        <ApplyPopup
          applyPopup={applyPopup}
          setApplyPopup={setApplyPopup}
          setSuccess={setSuccess}
        />
      ) : null}
      {success.process === 'completed' ? (
        <div className='application-submission'>
          {success.status ? (
            <div className='success'>
              <Lottie
                className='lottie'
                loop
                animationData={successLottie}
                play
              />
              <h3>Your Application Submitted Successfully</h3>
            </div>
          ) : (
            <div className='failed'>
              <Lottie
                className='lottie'
                loop
                animationData={failed}
                play
              />
              <h3>OOPS! Something went wrong, Please try again later</h3>
              <button onClick={() => window.location.reload()}>Reload</button>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Jobs;
