import axios from 'axios';
import { backendUrl } from '../../global';
import { CustomQuestionSubmission } from './components/Interface';

const headerProvider = (token: string) => {
  const config = {
    headers: {
      'acciojobs-token': token,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    },
  };
  return config;
};

export const fetchCustomQuestionsService = async (
  jobCardId: string,
  token: string
) => {
  const url = `${backendUrl}/job-card/getQuestions?jobCardId=${jobCardId}`;
  try {
    const { data } = await axios.get(url, headerProvider(token));
    return data;
  } catch (error) {
    console.log('ERROR', error);
  }
};

export const getJobCardApplicationStatus = async (
  jobCardId: string,
  token: string
) => {
  const url = `${backendUrl}/job-card/userJobCardStatus?jobCardId=${jobCardId}`;
  try {
    const { data } = await axios.get(url, headerProvider(token));
    return data;
  } catch (error) {
    console.log('ERROR', error);
  }
};

export const fetchCompanyFromJobCardIdService = async (
  jobCardId: string,
  token: string
) => {
  const url = `${backendUrl}/job-card/getJobCardCompanyFromJobCardId?jobCardId=${jobCardId}`;
  try {
    const { data } = await axios.get(url, headerProvider(token));
    return data.data.companyName;
  } catch (error) {
    console.log('ERROR', error);
  }
};

export const postCustomQuestionsService = async (
  customQuestionData: CustomQuestionSubmission,
  token: string
) => {
  const url = `${backendUrl}/job-card/applyJobCard`;
  try {
    const { data } = await axios.post(
      url,
      {
        ...customQuestionData,
      },
      headerProvider(token)
    );
    return data;
  } catch (error) {
    console.log('ERROR', error);
  }
};
